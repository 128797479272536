import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private TOKEN_KEY = 'access_token';
  private EXPIRES_KEY = 'token_expires_at';

  constructor(private router: Router) { }

  setToken(token: string, expiresIn?: number): void {
    localStorage.setItem(this.TOKEN_KEY, token);
    const expirationTime = new Date().getTime() + expiresIn * 1000;
    localStorage.setItem(this.EXPIRES_KEY, expirationTime.toString());
  }

  getToken(): string | null {
    if (this.isTokenExpired()) {
      this.removeToken();
      return null;
    }
    return localStorage.getItem(this.TOKEN_KEY);
  }

  removeToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.EXPIRES_KEY);
  }

  removeCurrentUser() {
    localStorage.removeItem('currentUser');
  }

  isTokenExpired(): boolean {
    const expiration = localStorage.getItem(this.EXPIRES_KEY);
    if (!expiration) return true;

    const now = new Date().getTime();
    return now > parseInt(expiration, 10);
  }

  handleTokenExpiration(): void {
    this.removeToken();
    this.removeCurrentUser();
    this.router.navigate(['/']).then();
  }

}
