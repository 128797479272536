<div class="page-wrap p-3">
  <div class="page-header mb-3 mt-3 d-flex align-items-center justify-content-between">
    <div class="page-header-info d-flex">
      <ng-container *ngIf="icon">
        <span class="icon"></span>
      </ng-container>
      <div class="page-header-info-text">
        <div class="title">{{title ? (title | translate ) : ''}}</div>
        <div class="sub-title">{{subTitle ? (subTitle | translate ) : ''}}</div>
      </div>
    </div>
    <ng-container *ngIf="pageHeaderActions">
      <div class="page-actions d-flex align-items-center">
        <ng-container *ngTemplateOutlet="pageHeaderActions"></ng-container>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="pageFilter">
    <div class="filters mt-3 mb-3">
      <div class="row">
        <ng-container *ngTemplateOutlet="pageFilter"></ng-container>
      </div>
    </div>
  </ng-container>
  <div class="page-content mt-3 mb-3">
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="pageFooter">
    <div class="page-footer">
      <ng-container *ngTemplateOutlet="pageFooter"></ng-container>
    </div>
  </ng-container>
</div>
