import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmDialogComponent} from '../../../../common/dialogs/confirm-dialag/confirm-dialag.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-button',
  template: `
    <a class="btn btn-outline-danger delete-btn"
       [ngClass]="[disabled?'disabled':'']"
       (click)="deleteEvent($event)" tabindex="-1">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </a>
  `
})

export class DeleteButtonComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() confirm: boolean;
  @Input() confirmMessage: String = 'Вы действительно хотите удалить?';
  @Input() title: String;

  @Output() onClick = new EventEmitter<any>();

  constructor(private _dialog: MatDialog) {

  }

  ngOnInit() {
  }

  deleteEvent(event) {
    if (this.disabled) {
      return;
    }

    if (!this.confirm) {
      this.onClick.emit(event);
    } else {
      this._dialog.closeAll();
      const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: {
          message: this.confirmMessage
        },
      });

      dialogRef.afterClosed().subscribe(
        (result) => {
          if (result) {
            this.onClick.emit(event);
          }
        }
      );
    }
  }

}
