import {Injectable, Injector} from '@angular/core';
import {HttpContext, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {JwtService} from "./jwt.service";
import {LocalStorageService} from "./local-storage.service";
import {Observable} from "rxjs";
import {BaseService} from "./base.service";
import {UserAuth} from "../models/user-auth";
import {UserService} from "./user.service";
import {SKIP_INTERCEPTOR} from "../tokens/skip-interceptor.token";

@Injectable({
  providedIn: 'root'
})
export class UserAuthService extends BaseService {

  isLoggedIn = false;

  constructor(
    protected override injector: Injector,
    private jwtService: JwtService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private router: Router,
  ) {
  super(injector);
}

  login(body: UserAuth) : Observable<any> {
    const headers = new HttpHeaders({
      'Current-Language': localStorage.getItem('systemLanguageCode'),
    });
    return this.post(`auth/login`, body, { context: new HttpContext().set(SKIP_INTERCEPTOR, true), headers });
  }

  handleLoginSuccess(response: any): Observable<any> {
    const token = response.token;
    const expiresIn = this.jwtService.decodeToken(token).exp;
    this.localStorageService.setToken(token, expiresIn);
    return this.userService.getUser();
  }

  logout(): void {
    this.localStorageService.removeToken();
    this.localStorageService.removeCurrentUser();
    this.userService.clearCurrentUser();
    this.router.navigate(['/']).then();
  }

  isAuthenticated(): boolean {
    return !this.localStorageService.isTokenExpired();
  }
}
