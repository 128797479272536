import {inject, Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {Message} from "../../modules/shared/interfaces/response-message.interface";

@Injectable({providedIn: 'root' })
export class ToastrUtil {
  private toastrService = inject(ToastrService);

  showMessage(message: Message) {
    if (message.type === 'SUCCESS') {
      this.toastrService.success(message.message);
    } else if (message.type === 'WARNING') {
      this.toastrService.warning(message.message);
    } else {
      this.toastrService.error(message.message);
    }
  }
}
