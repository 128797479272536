import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {BaseService} from "./base.service";

export class UserData {
  id: number;
  iin: string;
  fullname: string;
  lastname: string;
  firstname: string;
  patronymic: string;
  type: string;
}
@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  private currentUserSubject = new BehaviorSubject<any>(null);
  currentUser$ = this.currentUserSubject.asObservable();

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
    this.loadUserFromLocalStorage();
  }

  getUser(): Observable<any> {
    return this.get('user/info', {});
  }

  setCurrentUser(user: any): void {
    this.currentUserSubject.next(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUserTypes(): Observable<any> {
    return this.get('user/types', {});
  }

  clearCurrentUser(): void {
    this.currentUserSubject.next(null);
  }

  getCurrentUser(): any {
    return this.currentUserSubject.value;
  }

  private loadUserFromLocalStorage(): void {
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      this.currentUserSubject.next(JSON.parse(storedUser));
    }
  }
}
