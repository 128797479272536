export class Language {

  public static RUSSIAN = new Language(1, 'Русский', 'ru');
  public static KAZAKH = new Language(2, 'Қазақша', 'kz');
  public static ENGLISH = new Language(3, 'English', 'en');
  public static SYSTEM_LANGUAGE_CODE_LABEL = 'systemLanguageCode';
  public static languages = [Language.KAZAKH, Language.RUSSIAN, Language.ENGLISH];

  public id: number;
  public name: string;
  public code: string;

  constructor(id, name, code) {
    this.id = id;
    this.name = name;
    this.code = code;
  }

  static getLangID(langCode: string): number {
    return langCode === Language.RUSSIAN.code
      ? Language.RUSSIAN.id : langCode === Language.ENGLISH.code ? Language.ENGLISH.id : Language.KAZAKH.id;
  }

  static getCurrentLanguage(): Language {
    const currentLanguageCode = localStorage.getItem(Language.SYSTEM_LANGUAGE_CODE_LABEL);
    return this.getLangObject(currentLanguageCode);
  }

  static updateListLocalizedName(objectList: any[]): void {
    if (objectList) {
      for (let i = 0; i < objectList.length; i++) {
        this.updateLocalizedName(objectList[i]);
      }
    }
  }

  static getLocalizedNameList(objectList: any[]): any[] {
    const tempList = [];
    if (objectList) {
      for (let i = 0; i < objectList.length; i++) {
        tempList.push(this.updateLocalizedName(objectList[i]));
      }
    }
    return tempList;
  }

  static getFilterObjectAll(id?: any) {
    if (!id) {
      id = 0;
    }
    return {
      id: id,
      nameKz: 'Барлығы',
      nameRu: 'Все',
      nameEn: 'All',
      code: ''
    };
  }

  static getFilterObjectNotChosen(id?: any) {
    if (!id) {
      id = -1;
    }
    return {
      id: id,
      nameKz: 'Таңдалмаған',
      nameRu: 'Не выбран',
      nameEn: 'Not chosen',
      code: ''
    };
  }

  static updateListLocalizedFullName(objectList: any): void {
    if (objectList) {
      for (let i = 0; i < objectList.length; i++) {
        this.updateLocalizedFullName(objectList[i]);
      }
    }
  }

  // get localized name of multilanguage object
  static updateLocalizedName(object: any): any {
    if (object) {
      const lang = this.getCurrentLanguage();
      if (lang === Language.RUSSIAN) {
        object.name = object.nameRu;
      } else if (lang === Language.ENGLISH) {
        object.name = object.nameEn;
      } else if (lang === Language.KAZAKH) {
        object.name = object.nameKz;
      }
    }
    return object;
  }

  static getLocalizedNameObject(object: any): any {
    return this.updateLocalizedName(object);
  }

  // get localized full name of multilanguage object
  static updateLocalizedFullName(object: any): void {
    if (object) {
      const lang = this.getCurrentLanguage();
      if (lang === Language.RUSSIAN) {
        object.fullName = object.fullNameRu;
      } else if (lang === Language.ENGLISH) {
        object.fullName = object.fullNameEn;
      } else if ((lang === Language.KAZAKH)) {
        object.fullName = object.fullNameKz;
      }
    }
  }

  // get localized faculty name of multilanguage object
  static updateLocalizedFacultyName(object: any): void {
    if (object) {
      const lang = this.getCurrentLanguage();
      if (lang === Language.RUSSIAN) {
        object.name = object.facultyNameRu;
      } else if (lang === Language.ENGLISH) {
        object.name = object.facultyNameEn;
      } else if ((lang === Language.KAZAKH)) {
        object.name = object.facultyNameKz;
      }
    }
  }

  static getLangObject(langCode): Language {
    switch (langCode) {
      case Language.KAZAKH.code: {
        return Language.KAZAKH;
      }
      case Language.RUSSIAN.code: {
        return Language.RUSSIAN;
      }
      case Language.ENGLISH.code: {
        return Language.ENGLISH;
      }
      default: {
        return Language.KAZAKH;
      }
    }
  }

  suffix(): string {
    return this.code.toUpperCase();
  }
}
