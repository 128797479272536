import {Component, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-page-wrap',
  templateUrl: './page-wrap.component.html',
  styleUrls: ['./page-wrap.component.scss']
})
export class PageWrapComponent implements OnInit {

  @Input() title = '';
  @Input() subTitle? = '';
  @Input() icon? = '';
  @Input() pageHeaderActions?: TemplateRef<any> | null = null;
  @Input() pageFilter?: TemplateRef<any> | null = null;
  @Input() pageFooter?: TemplateRef<any> | null = null;

  constructor() {
  }

  ngOnInit() {
  }
}
