import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
// import {IJwt} from "../interfaces/jwt.interface"; // TODO

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      console.error('Invalid token', Error);
      return null;
    }
  }

}
