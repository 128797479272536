<nav class="navbar navbar-expand-lg navbar-light shadow-sm bg-light fixed-top com-index-nav">
  <div class="container">
    <!-- Логотип -->
    <a class="navbar-brand d-flex align-items-center" routerLink="/">
      <div class="logo-wrap">
        <img src="/assets/images/logo.png" alt="">
      </div>
      <span class="logo-text pl-3">
        <p>Sarbaz</p>
        <span>{{'Information system' | translate }}</span>
      </span>
    </a>

    <!-- Кнопка для раскрытия навбара на мобильных устройствах -->
    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbar4" aria-controls="navbar4" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Основное содержимое навбара -->
    <div class="collapse navbar-collapse" id="navbar4">

      <!-- Правые элементы меню -->
      <ul class="navbar-nav ms-auto">
        <!-- Языковое меню -->
        <li class="nav-item dropdown" id="langDropdownWrap">
          <a class="nav-link dropdown-toggle d-flex align-items-center text-underline" href="#" id="langDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-caret-down-fill me-2"></i>
            {{currentLanguage?.name}}
          </a>
          <ul class="dropdown-menu content-lang-dropdown" aria-labelledby="langDropdown">
            <li>
              <a class="dropdown-item" href="javascript:void(0)" (click)="changeLanguage(languages[0].code)">
                {{languages[0].name}}
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="javascript:void(0)" (click)="changeLanguage(languages[1].code)">
                {{languages[1].name}}
              </a>
            </li>
            <!--<li>
              <a class="dropdown-item" href="javascript:void(0)" (click)="changeLanguage(languages[2].code)">
                {{languages[2].name}}
              </a>
            </li>-->
          </ul>
        </li>

        <!-- Уведомления -->
        <!--<li class="nav-item dropdown d-flex" id="notifyDropdown">
          <a class="nav-link dropdown-toggle d-flex align-items-center pl-2" href="#" id="notificationDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-solid fa-bell"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-start content-notification-dropdown" aria-labelledby="notificationDropdown">
            <li class="d-flex align-items-center justify-content-around">
              <h6>{{'New notifications' | translate }}</h6>
              <label class="label label-danger d-flex">
                <a routerLink="notification-list" class="pr-1 text-white" [queryParams]="{'status':'UNREAD'}">
                  {{notificationCount}}
                </a>
                <span translate="no"></span>
              </label>
            </li>
            <li class="waves-effect waves-light" *ngFor="let item of notificationList">
              <div class="media" (click)="goToNotificationPage(item)">
                <div class="media-body">
                  <p class="notification-msg">{{item.message}}</p>
                </div>
              </div>
            </li>
            <li class="text-center">
              <a routerLink="notification-list" translate="common.show_all" class="btn-href"> </a>
            </li>
          </ul>
        </li>-->

        <!-- Кнопка "Войти" -->
        <li class="nav-item" *ngIf="!userAuthService.isAuthenticated()">
          <a class="nav-link text-underline pl-2" routerLink="/auth">
            <span>{{ 'Enter' | translate }}</span>
          </a>
        </li>
        <li class="nav-item dropdown" *ngIf="userAuthService.isAuthenticated()" id="authDropdownWrap">
          <a class="nav-link dropdown-toggle d-flex align-items-center text-underline"
             routerLink="" id="authDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-caret-down-fill me-2"></i>
            {{currentUser?.firstname}}
          </a>
          <ul class="dropdown-menu content-auth-dropdown" aria-labelledby="authDropdown">
            <li>
              <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()">
                {{'Logout' | translate }}
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="javascript:void(0)" (click)="onCabinet()">
                {{'Account' | translate }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="index-main-container">
  <div class="content-menu d-flex justify-content-center">
    <nav class="navbar navbar-expand-lg p-0">
      <div class="container-fluid bottom-navbar-container">
        <button class="navbar-toggler bg-white bottom-navbar-btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation" style="display: none;">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav d-flex align-items-center navbar-nav-ul">
            <li class="nav-item">
              <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{'Main' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/methodical-material" routerLinkActive="active">{{'Methodological materials' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/participant-procedure" routerLinkActive="active">{{'Participation procedure' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/faq" routerLinkActive="active">{{'Question - Answer' | translate }}</a>
            </li>
            <!--<li class="nav-item">
              <a class="nav-link" routerLink="/search-conscript" routerLinkActive="active">{{'Search' | translate }}</a>
            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" routerLink="/survey" routerLinkActive="active">{{'Survey' | translate }}</a>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <router-outlet></router-outlet>
</div>

<footer>
  <div class="green-footer pt-3">
    <div class="container">
      <div class="row mt-3 pt-3 pb-3">
        <div class="col-xs-12 col-sm-12 col-md-3 mt-3 mb-3">
          <a class="footer-logo d-flex align-items-center" routerLink="/">
            <div class="logo-wrap">
              <img src="/assets/images/logo.png" alt="">
            </div>
            <span class="logo-text pl-2">
              <p>Sarbaz</p>
              <span>{{'Information system' | translate }}</span>
            </span>
          </a>
          <h6 class="mt-3 text-white list-group-item">{{'Footer description' | translate }}</h6>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 mt-3">
          <h4 class="list-group-item">{{'For new recruits' | translate }}</h4>
          <ul class="list-group">
<!--            <li class="list-group-item pl-0"><a routerLink="/" class="text-white text-underline">{{'Announcements' | translate }}</a></li>-->
            <li class="list-group-item pl-0"><a href="https://okulyk.kz/" target="_blank" class="text-white text-underline">{{'Electronic library' | translate}}</a></li>
            <li class="list-group-item pl-0"><a href="#" class="text-white text-underline">{{'About the project' | translate }}</a></li>
            <li class="list-group-item pl-0"><a routerLink="/support" class="text-white text-underline">{{'Support' | translate }}</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 mt-3">
          <h4 class="list-group-item">{{'Info' | translate }}</h4>
          <ul class="list-group">
            <li class="list-group-item pl-0"><a href="https://www.gov.kz/memleket/entities/mod?lang=kk" target="_blank" class="text-white text-underline">{{'ministry_of_defence' | translate }}</a></li>
            <li class="list-group-item pl-0"><a href="https://www.gov.kz/memleket/entities/sci?lang=kk" target="_blank" class="text-white text-underline">{{'ministry_of_science_high_education' | translate }}</a></li>
            <li class="list-group-item pl-0"><a href="https://www.gov.kz/memleket/entities/edu?lang=kk" target="_blank" class="text-white text-underline">{{'ministry_of_education' | translate }}</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 mt-3">
          <h4 class="list-group-item">{{'Need a consultation' | translate }}</h4>
          <ul class="list-group">
            <li class="list-group-item pl-0 text-white">{{'Please contact the following number for invitation questions' | translate }}:</li>
            <li class="list-group-item pl-0">
              <p class="text-white">+7 778 072 16 06</p>
              <p class="text-white">+7 778 072 12 70</p>
            </li>
            <li class="list-group-item pl-0 text-white">{{'Please contact the following number regarding discounted education' | translate }}:</li>
            <li class="list-group-item pl-0">
              <p class="text-white">+7 705 120 04 17 Людмила,</p>
              <p class="text-white">+7 708 764 16 16 Нурлан,</p>
              <p class="text-white">+7 707 209 14 62 Рустем, сайт әкімшісі</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-dark pt-3 pb-3 d-flex align-items-center justify-content-center">
    <h5 class="dark-footer-text"><span>Sarbaz</span> <span>2024</span></h5>
  </div>
</footer>
