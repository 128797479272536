import {Injectable, Injector} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  apiUrl = environment.apiUrl;
  protected http: HttpClient;

  constructor(
    protected injector: Injector
  ) {
    this.http = this.injector.get(HttpClient);
  }

  get(uri: string, params: any): Observable<any> {
    params = this.normalBody(params);
    const pars = this.getUrlParams(params);
    return this.http.get<any>(this.apiUrl + uri, { params: pars });
  }

  post(uri: string, body: any, options?: any): Observable<any> {
    body = this.normalBody(body);
    return this.http.post<any>(this.apiUrl + uri, body, options);
  }

  delete(uri: string, body: any): Observable<any> {
    body = this.normalBody(body);
    const params = new HttpParams({ fromObject: body });
    return this.http.delete<any>(this.apiUrl + uri, {
      body,
      params,
    });
  }

  put(uri: string, body: any): Observable<any> {
    body = this.normalBody(body);
    return this.http.put<any>(this.apiUrl + uri, body);
  }

  private normalBody(body: any): any {
    if (!body) {
      body = {};
    }
    for (const key in body) {
      if (!body.hasOwnProperty(key)) {
        continue;
      }
      if (body[key] instanceof Date) {
        // body[key] = this.formatDate(body[key]);
      }
    }
    return body;
  }

  // formatDate(date: any): string {
  //   // return moment(date).format('YYYY-MM-DD[T]HH:mm:ss');
  // }

  getUrlParams(body: any): HttpParams {
    let params = new HttpParams();
    for (const key in body) {
      if (
        !body.hasOwnProperty(key) ||
        body[key] === null ||
        body[key] === undefined
      ) {
        continue;
      }
      if (Array.isArray(body[key])) {
        const arr: any[] = body[key];
        arr.forEach((value) => (params = params.append(key, value)));
        continue;
      }
      params = params.append(key, body[key]);
    }
    return params;
  }
}
