import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialag',
  templateUrl: './confirm-dialag.component.html',
  styleUrls: ['./confirm-dialag.component.scss']
})
// This is just confirmation dialog for two values yes or no
export class ConfirmDialogComponent implements OnInit {

  msg = 'Change This string'; // this.data.message
  acceptMessage = 'yes';
  declineMessage = 'no';

  constructor(public _dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    this.msg = this.data.message;
    if (this.data.acceptMessage && this.data.acceptMessage.length > 0) {
      this.acceptMessage = this.data.acceptMessage;
    }
    if (this.data.declineMessage && this.data.declineMessage.length > 0) {
      this.declineMessage = this.data.declineMessage;
    }
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this._dialogRef.close();
  }

  confirmed() {
    this._dialogRef.close(true);
  }

  notConfirmed() {
    this._dialogRef.close(false);
  }
}
