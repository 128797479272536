import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {UserAuthService} from "../services/user-auth.service";

export const authUserGuard: CanActivateFn = (route, state) => {
  const authUserService = inject(UserAuthService);
  const router = inject(Router);
  if (authUserService.isAuthenticated()) {
    router.navigate(['/']).then();
    return false;
  }
  return true;
};
