import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {BaseService} from "./base.service";

export interface IGeneratedData {
  password: string;
  fullname: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class PassingLocalDataService extends BaseService {

  private currentLang = new BehaviorSubject(localStorage.getItem('systemLanguageCode'));
  currentLangObserver$ = this.currentLang.asObservable();

  private generatedList = new BehaviorSubject(localStorage.getItem('generatedUsers'));
  generatedListObserver$ = this.generatedList.asObservable();

  private generatedConscripts = new BehaviorSubject(localStorage.getItem('generatedConscripts'));
  generatedConscriptsObserver$ = this.generatedConscripts.asObservable();

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
  }

  setCurrentLang(lang: string): void {
    this.currentLang.next(lang);
  }

  setGeneratedList(list: IGeneratedData[]) : void {
    this.generatedList.next(JSON.stringify(list));
  }

  setGeneratedConscriptList(list: IGeneratedData[]) : void {
    this.generatedConscripts.next(JSON.stringify(list));
  }
}
