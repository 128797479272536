<div style="width: 100%">
  <div mat-dialog-content>
    {{ msg | translate }}
  </div>
  <div class="d-flex justify-content-center" mat-dialog-actions>
    <button class="btn btn-success btn-sm" (click)="confirmed()">{{ acceptMessage | translate }}</button>
    <button class="btn btn-danger btn-sm" style="margin-left: 10px" (click)="notConfirmed()">
      {{ declineMessage | translate }}
    </button>
  </div>
</div>
