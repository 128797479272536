import {Component, OnInit} from '@angular/core';
import {Language} from "../../../../common/models/language";
import {TranslateService} from "@ngx-translate/core";
import {UserData, UserService} from "../../../../common/services/user.service";
import {UserAuthService} from "../../../../common/services/user-auth.service";
import {Router} from "@angular/router";
import {PassingLocalDataService} from "../../../../common/services/passing-local-data.service";

@Component({
  selector: 'app-common-index',
  templateUrl: './common-index.component.html',
  styleUrls: ['./common-index.component.scss']
})
export class CommonIndexComponent implements OnInit {

  notificationCount = 0;
  notificationList: any[] = [];
  selectedIndex = 0;
  languages: any[] = [];
  currentLanguage: Language;
  currentUser: UserData | null;

  constructor(
    private _translateService: TranslateService,
    private userService: UserService,
    public userAuthService: UserAuthService,
    private router: Router,
    private passingLocalData: PassingLocalDataService,
  ) {
  }

  ngOnInit() {
    this.languages = Language.languages;
    this.currentLanguage = Language.getCurrentLanguage();

    this.userService.currentUser$.subscribe((response) => {
      this.currentUser = response;
    })
  }

  onLogout() {
    this.userAuthService.logout();
  }

  changeLanguage(languageCode: string) {
    localStorage.setItem(Language.SYSTEM_LANGUAGE_CODE_LABEL, languageCode);
    this._translateService.setDefaultLang(languageCode);
    this._translateService.use(languageCode);
    this.currentLanguage = Language.getLangObject(languageCode);
    this.passingLocalData.setCurrentLang(languageCode);
  }

  goToNotificationPage(item: any) {
    console.log(item)
  }

  onCabinet() {
    if (this.currentUser && this.currentUser.type && this.currentUser.type == "CONSCRIPT") {
      this.router.navigate(['/account/testing/welcome']).then();
    } else {
      this.router.navigate(['/account']).then();
    }
  }
}
