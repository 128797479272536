import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {ToastrUtil} from "../utils/toastr.util";
import {inject} from "@angular/core";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {SKIP_INTERCEPTOR} from "../tokens/skip-interceptor.token";
import {Message, ResponseMessage} from "../../modules/shared/interfaces/response-message.interface";

export class ErrorHandlerInterceptor implements HttpInterceptor {
  private toastrUtil = inject(ToastrUtil);

  private errors: any;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.has(SKIP_INTERCEPTOR)) return next.handle(req);

    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status === 401 || !(typeof err.error === 'object')) return throwError(() => err);

      this.showToastr(err.error);
      return throwError(() => `Error(s) in the request: "${req.method} ${req.url}": ${JSON.stringify(this.errors)}`);
    }));
  }

  private showToastr(error: unknown) {
    if (error.hasOwnProperty('message')) {
      this.toastrUtil.showMessage(error as Message);
      this.errors = error;
    } else {
      Object.keys(error).forEach((key: string) => this.toastrUtil.showMessage(error[key]));
      this.errors = Object.entries(error).map((y) => y);
    }
  }
}
