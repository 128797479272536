import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '../models/language';
import {ConfirmDialogComponent} from '../dialogs/confirm-dialag/confirm-dialag.component';
import {MatDialog} from '@angular/material/dialog';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ToastrService} from "ngx-toastr";

declare let $: any;
declare let CryptoJS: any;

@Injectable({
  providedIn: 'root'
})
export class CommonUtil {

  @BlockUI() blockUI: NgBlockUI;

  constructor(public translateService: TranslateService,
              private toastrService: ToastrService,
              private _dialog: MatDialog,
  ) {
  }

  notifyResponse(response) {
    this.notifyMessage({
      status: response.type.toLowerCase(),
      description: response.message,
      additional: null
    }, null, null);
  }

  notifyErr(exc) {
    this.toastrService.warning(this.translateService.instant(exc.error.message));
  }

  notifyError(description, additional = null) {
    this.toastrService.error(this.translateService.instant(description, additional));
  }

  notifySuccess(description, additional?) {
    this.toastrService.success(this.translateService.instant(description, additional));
  }

  notifyWarning(description, additional?) {
    this.toastrService.warning(this.translateService.instant(description, additional));
  }

  notifyMessage(message, successFn, errorFn) {
    if (!message) {
      return;
    }
    switch (message.status) {
      case 'error':
        this.toastrService.error(this.translateService.instant(message.description, message.additional));
        break;
      case 'success':
        this.toastrService.success(this.translateService.instant(message.description, message.additional));
        break;
      case 'warning':
        this.toastrService.warning(this.translateService.instant(message.description, message.additional));
        break;
    }
  }

  translateList(list, prop) {
    const resultList = [];
    if (list && list.length) {
      for (let i = 0; i < list.length; i++) {
        list[i][prop] = this.getMultiValue(list[i], prop);
        resultList.push(list[i]);
      }
    }
    return resultList;
  }

  getMultiValue = function (obj, prop) {
    if (this.translateService.currentLang == Language.KAZAKH.code) {
      return obj[prop + 'Kz'] || obj[prop + 'KZ'] || '';
    } else if (this.translateService.currentLang == Language.ENGLISH.code) {
      return obj[prop + 'En'] || obj[prop + 'EN'] || '';
    }
    return obj[prop + 'Ru'] || obj[prop + 'RU']  || '';
  };

  confirmDialog(option, fn, fnNotConfirmed?) {
    option = option || {message: 'common.are_sure_to_delete'};
    option.message = option.message || 'common.are_sure_to_delete';
    let messageParams = {};
    if (option && option.messageParams) {
      messageParams = option.messageParams;
    }

    option.message = this.translateService.instant(option.message, messageParams);

    if (option.closeAll) {
      this._dialog.closeAll();
    }

    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      maxWidth: '45%',
      // height: '110px',
      data: option
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          if (fn) {
            fn();
          }
        } else {
          if (fnNotConfirmed) {
            fnNotConfirmed();
          }
        }
      }
    );
  }

  blockStart(message?) {
    if (!message) {
      message = 'Подождите, идет загрузка...';
    }
    this.blockUI.start(this.translateService.instant(message));
  }

  blockStop() {
    if (this.blockUI.isActive) {
      this.blockUI.stop();
    }
  }

  static removeNullFields(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }
}
