import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Language} from './common/models/language';
import {LocalStorageService} from "./common/services/local-storage.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    ) {
    this.initSystemLanguage();
  }

  ngOnInit(): void {
    const token = this.localStorageService.getToken();
    if (!token) {
      this.localStorageService.handleTokenExpiration();
    }
  }

  initSystemLanguage(): void {
    let systemLanguageCode = localStorage.getItem(Language.SYSTEM_LANGUAGE_CODE_LABEL);
    if (!systemLanguageCode) {
      systemLanguageCode = Language.RUSSIAN.code;
      localStorage.setItem(Language.SYSTEM_LANGUAGE_CODE_LABEL, systemLanguageCode);
    }
    this.translate.setDefaultLang(systemLanguageCode);
    this.translate.use(systemLanguageCode);
  }
}
