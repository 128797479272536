import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'dynamicTranslate',
    pure: false
})
export class DynamicTranslatePipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(obj, field?, filterById?) {
        if (obj == null) {
            return '';
        }

        let value = obj;
        if (filterById) {
            let values = obj.filter(item => item.id == filterById);
            if (values != null && values.length > 0) {
                value = values[0];
            } else {
                return '';
            }
        }

        if (field == null) {
            field = 'name';
        }

        if (value instanceof Object) {
            if (this.translate.store.currentLang === 'kk') {
                return value[field + 'KZ'] || value[field + 'Kz'] || value[field] || '';
            }
            return value[field + 'RU'] || value[field + 'Ru'] || value[field] || '';
        }
        return this.translate.instant(value + '');
    }
}
