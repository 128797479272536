import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonUtil} from '../../common/utils/common.util';
import {ConfirmDialogComponent} from '../../common/dialogs/confirm-dialag/confirm-dialag.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from '../../common/interceptors/auth.interceptor';
import {RouterModule} from '@angular/router'; // <-- import the module
import {OrderModule} from 'ngx-order-pipe';
import {MatDialogModule} from "@angular/material/dialog";
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from "ng-recaptcha";
import {environment} from "../../../environments/environment";
import {CommonIndexComponent} from "./components/common-index/common-index.component";
import {DynamicTranslatePipe} from './pipes/dynamic-translate.pipe';
import {DeleteButtonComponent} from "./components/delete-button/delete-button.component";
import {SafeResourceUrlPipe} from "./pipes/safe-resource-url.pipe";
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { PageWrapComponent } from './components/page-wrap/page-wrap.component';
import {MatPaginatorModule} from "@angular/material/paginator";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HttpClientModule,
    RouterModule,
    OrderModule,
    MatDialogModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxMaskDirective,
    MatPaginatorModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    CommonIndexComponent,
    DeleteButtonComponent,
    DynamicTranslatePipe,
    SafeResourceUrlPipe,
    PageWrapComponent,
  ],
  providers: [
    CommonUtil,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    provideEnvironmentNgxMask(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HttpClientModule,
    OrderModule,
    DynamicTranslatePipe,
    SafeResourceUrlPipe,
    DeleteButtonComponent,
    NgxMaskDirective,
    PageWrapComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
}
